<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <hr class="m-0">
    <div
      ref="treeStructureRef"
      class="overflow-auto"
      v-html="html"
    />
    <b-modal
      id="modal-link"
      ref="linkModalRef"
      v-model="showLinkModal"
      cancel-variant="outline-secondary"
      ok-title="Закрыть"
      ok-only
      centered
      title="Реферальная ссылка"
    >
      <b-form>
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="promo-link"
            v-model="link"
          />
          <b-input-group-append is-text>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="doCopy"
            >
              Скопировать
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-dialog-moda"
      ref="refModal"
      v-model="showDialogModal"
      title="Добавить партнера"
      size="lg"
      hide-footer
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          :disabled="loadingCreate"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row
            class="match-height"
          >
            <b-col>
              <div>
                <text-input
                  id="xbu_LN"
                  name="xbu_LN"
                  :value.sync="xbu_LN"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="Фамилия"
                  api=""
                />
                <text-input
                  id="xbu_FN"
                  name="xbu_FN"
                  :value.sync="xbu_FN"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="Имя"
                  api=""
                />
                <text-input
                  id="xbu_MN"
                  name="xbu_MN"
                  :value.sync="xbu_MN"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="Отчество"
                  api=""
                />
                <phone-input
                  id="xbu_tel"
                  name="xbu_tel"
                  :value.sync="xbu_tel"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="Телефон"
                  api=""
                />
                <text-input
                  id="xbu_mail"
                  name="xbu_mail"
                  :value.sync="xbu_mail"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="E-mail"
                  api=""
                />
                <b-form-group
                  label="Дата рождения"
                  label-for="xbu_DateOfBrith"
                >
                  <date-picker
                    id="xbu_DateOfBrith"
                    v-model="xbu_DateOfBrith"
                    class="w-100"
                    format="DD.MM.YYYY"
                    placeholder="дд.мм.гггг"
                  />
                </b-form-group>
                <text-input
                  id="xbu_town"
                  name="xbu_town"
                  :value.sync="xbu_town"
                  :get-validation-state="getValidationState"
                  rules=""
                  label="Город"
                  api=""
                />                                      </div>
            </b-col>
            <b-col>
              <div>
                <search-select-input
                  id="xbu_buyerStruct"
                  name="xbu_buyerStruct"
                  :value.sync="xbu_buyerStruct"
                  :init-api="`https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/search/list/reg?q=${q}`"
                  :get-option-label="option =&gt; option.query"
                  label="Кто пригласил (спонсор)"
                  placeholder="Номер, ФИО, телефон, email"
                  search-label="Номер, ФИО, телефон, email"
                  api="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/search/list/reg"
                />
                <!-- <select-input
                  id="xbu_inpt"
                  name="xbu_inpt"
                  :value.sync="xbu_inpt"
                  :get-option-label="option =&gt; option.text"
                  :reduce="val =&gt; val.value"
                  label="Тип входа"
                  api="https://api.prohealthfactor.ru/api/v1/json/lkb/service/data/list/inptprog/reg"
                /> -->
                <b-form-group
                  label="Ветка"
                  label-for="xbu_branch"
                >
                  <div class="d-flex">
                    <b-form-radio
                      id="xbu_branch"
                      v-model="xbu_branch"
                      name="Левая"
                      value="0"
                      class="mr-50"
                    >
                      Левая
                    </b-form-radio>
                    <b-form-radio
                      v-model="xbu_branch"
                      name="Правая"
                      value="1"
                    >
                      Правая
                    </b-form-radio>
                  </div>
                </b-form-group>
                <!-- <select-input
                  id="xbu_wh"
                  name="xbu_wh"
                  :value.sync="xbu_wh"
                  :get-option-label="option =&gt; option.text"
                  :reduce="val =&gt; val.value"
                  label="Офис обслуживания"
                  api="https://api.prohealthfactor.ru/api/v1/json/lkb/service/data/list/office/reg"
                /> -->
              </div>
            </b-col>
          </b-row>
          <hr
            style="margin-left:-1.4rem;margin-right:-1.4rem;"
          >
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              variant="flat-danger"
              class="mr-50"
              @click="hideModal()"
            >
              Отмена
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              :disabled="loadingCreate"
            >
              <b-spinner
                v-if="loadingCreate"
                small
                class="mr-50"
              />
              <span>Добавить</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BOverlay, BModal, BForm, BSpinner,
  BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ref, onUnmounted, nextTick,
  watch,
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'
import TextInput from '@core/components/input/text-input/TextInput.vue'
import PhoneInput from '@core/components/input/phone-input/PhoneInput.vue'
// import SelectInput from '@core/components/input/select-input/SelectInput.vue'
import SearchSelectInput from '@core/components/input/search-select-input/SearchSelectInput.vue'
import { useRouter } from '@core/utils/utils'
import moment from 'moment'
import treeStructureStoreModule from './treeStructureStoreModule'
import store from '@/store'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BModal,
    BForm,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationObserver,
    TextInput,
    PhoneInput,
    // SelectInput,
    SearchSelectInput,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
    },
    xparam1: {
      type: [String, Number, null],
      required: false,
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'tree-structure'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, treeStructureStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router, route } = useRouter()

    const apiId = router.currentRoute.params.id

    const treeStructureRef = ref(null)
    const refModal = ref(null)
    const html = ref('')
    const loading = ref(false)

    const fetchData = id => {
      loading.value = true
      store.dispatch('tree-structure/fetchData', {
        api: props.api,
        apiId: id,
      })
        .then(response => {
          const { data } = response
          html.value = data
          loading.value = false

          nextTick(() => {
            const links = treeStructureRef.value.querySelectorAll('[filter="url(#solid)"]')
            const rects = treeStructureRef.value.querySelectorAll('rect')

            if (rects && rects.length) {
              rects.forEach(r => {
                const onclick = r.getAttribute('onclick')
                if (onclick && onclick.indexOf('getSVGBoxClick') > -1) {
                  // eslint-disable-next-line
                  r.onclick = handleBlockClick
                }
              })
            }

            if (links && links.length) {
              links.forEach(l => {
                // eslint-disable-next-line
                l.onclick = handleLinkClick
              })
            }
          })
        })
        .catch(() => {
          html.value = ''
          loading.value = false
        })
    }

    const loadingCreate = ref(false)
    /* eslint-disable */
    const xbu_LN = ref('')
    const xbu_FN = ref('')
    const xbu_MN = ref('')
    const xbu_tel = ref('')
    const xbu_mail = ref('')
    const xbu_DateOfBrith = ref('2021-12-21')
    const xbu_town = ref('')
    const xbu_buyerStruct = ref('0')
    const xbu_inpt = ref('3')
    // const xbu_wh = ref('1')
    const xbu_branch = ref('0')
    /* eslint-enable */

    const resetData = () => {
      xbu_LN.value = ''
      xbu_FN.value = ''
      xbu_MN.value = ''
      xbu_tel.value = ''
      xbu_mail.value = ''
      xbu_DateOfBrith.value = ''
      xbu_town.value = ''
      xbu_buyerStruct.value = ''
      xbu_inpt.value = ''
      // xbu_wh.value = ''
      xbu_branch.value = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const hideModal = () => {
      resetForm()
      refModal.value.hide()
    }

    const onSubmit = () => {
      ctx.root.$swal({
        icon: 'question',
        html: `Зарегистрировать нового партнера: <br>${xbu_LN.value} ${xbu_FN.value} ${xbu_MN.value}? <br>Пригласивший: <br>${xbu_buyerStruct.value.query}, <br>ветка ${xbu_branch.value === '0' ? 'левая' : 'правая'}`,
        showCancelButton: true,
        confirmButtonText: 'Зарегистрировать',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.isConfirmed) {
          loadingCreate.value = true
          const formData = {
            xbu_ln: xbu_LN.value,
            xbu_fn: xbu_FN.value,
            xbu_mn: xbu_MN.value,
            xbu_tel: xbu_tel.value,
            xbu_mail: xbu_mail.value,
            xbu_dateofbrith: xbu_DateOfBrith.value && moment(xbu_DateOfBrith.value).format('MM.DD.YYYY'),
            xbu_town: xbu_town.value,
            xbu_buyerstruct: xbu_buyerStruct.value.id || xbu_buyerStruct.value,
            xbu_inpt: xbu_inpt.value,
            // xbu_wh: xbu_wh.value,
            xbu_branch: xbu_branch.value,
          }

          axios.post('https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/contract/add', formData)
            .then(response => {
              const { data } = response.data

              hideModal()
              fetchData(route.value.params.id)

              ctx.root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loadingCreate.value = false
            })
            .catch(err => {
              const { data } = err.response.data

              ctx.root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loadingCreate.value = false
            })
        }
      })
    }

    if (props.api) {
      fetchData(apiId)
    }

    watch([route], () => {
      fetchData(route.value.params.id)
    })

    watch(() => props.apiId, () => {
      if (props.apiId) {
        router.push({
          name: 'tree-structure-id',
          params: {
            id: props.apiId.id,
          },
        })
      } else {
        router.push({
          name: 'tree-structure',
        })
      }
    })

    watch(() => props.xparam1, () => {
      const g = treeStructureRef.value.querySelector('#id_mainG')
      if (g) {
        const m = (+props.xparam1.replace('%', '')) / 100
        g.setAttribute('transform', `scale(${m})`)
      }
    })

    const showLinkModal = ref(false)
    const showDialogModal = ref(false)
    const q = ref('--1')
    const linkModalRef = ref(null)
    const link = ref('')

    const handleLinkClick = ev => {
      const matches = ev.target.parentNode.getAttribute('onclick').match(/'(.*?)'/)
      link.value = matches
        ? matches[1]
        : ev.target.parentNode.getAttribute('onclick')

      showLinkModal.value = true
    }

    const handleBlockClick = ev => {
      const onclick = ev.target.getAttribute('onclick')
      const fnParams = onclick.substring(onclick.indexOf('(') + 1, onclick.lastIndexOf(')'))
      const args = fnParams.split(',')

      if (args[0] !== '0') {
        router.push({
          name: 'tree-structure-id',
          params: {
            id: args[0],
          },
        })
      } else {
        // eslint-disable-next-line
        q.value = args[2]
        // eslint-disable-next-line
        xbu_branch.value = args[3]
        showDialogModal.value = true
      }
    }

    const doCopy = () => {
      navigator.clipboard.writeText(link.value).then(() => {
        ctx.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ссылка скопирована',
            icon: 'BellIcon',
          },
        })
      }, () => {
        ctx.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Не удалось скопировать ссылку',
            icon: 'BellIcon',
          },
        })
      })
    }

    return {
      treeStructureRef,
      loading,
      html,
      link,
      showLinkModal,
      q,
      showDialogModal,
      linkModalRef,

      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      loadingCreate,
      xbu_LN,
      xbu_FN,
      xbu_MN,
      xbu_tel,
      xbu_mail,
      xbu_DateOfBrith,
      xbu_town,
      xbu_buyerStruct,
      xbu_inpt,
      // xbu_wh,
      xbu_branch,
      refModal,
      hideModal,

      doCopy,
    }
  },
}
</script>
<style scoped>
  .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
  }
  .overflow-auto {
    overflow: auto
  }
</style>
